import { template as template_6dad722198634d7fbc54f689e5afd449 } from "@ember/template-compiler";
const FKLabel = template_6dad722198634d7fbc54f689e5afd449(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
