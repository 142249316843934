import { template as template_34f8cf84ca0a49a99da91ce5ea2888df } from "@ember/template-compiler";
const SidebarSectionMessage = template_34f8cf84ca0a49a99da91ce5ea2888df(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
